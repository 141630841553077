import React, { useState, useEffect } from "react";
import logoImage from "../imgs/Fonte-Sul-chumbo-positivo-sem-tagline.png";
import { Link } from "react-router-dom";

function Login() {
  const [showTitle, setShowTitle] = useState(false);
  const [showStrong, setShowStrong] = useState(false);
  const [showHero, setShowHero] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [heroOpacity0, setHeroOpacity0] = useState(false);
  const [loginOpacity1, setLoginOpacity1] = useState(false);

  useEffect(() => {
    const titleTimer = setTimeout(() => setShowTitle(true), 50);

    const strongTimer = setTimeout(() => setShowStrong(true), 1000);

    const transitionTimer = setTimeout(() => {
      setHeroOpacity0(true);

      setTimeout(function () {
        setShowHero(false);
        setShowLogin(true);
      }, 500);

      setTimeout(function () {
        setLoginOpacity1(true);
      }, 600);
    }, 2800);

    return () => {
      clearTimeout(titleTimer);
      clearTimeout(strongTimer);
      clearTimeout(transitionTimer);
    };
  }, []);

  return (
    <div id="container" style={{ alignItems: loginOpacity1 && "center" }}>
      <header className="loginHeader">
        <img className="logoLogin" src={logoImage} alt="logo FonteSul" />
        <a href="https://fontesul.com.br/" target="_blank" rel="noreferrer">
          Saiba Mais
        </a>
      </header>
      <div id="main">
        {showHero && (
          <div id="hero" style={{ opacity: heroOpacity0 && 0 }}>
            <h1 id="title" className={showTitle ? "show" : "hide"}>
              Energia para ir <br />
              <strong id="strong" className={showStrong ? "" : "hide"}>
                ADIANTE
              </strong>
            </h1>
          </div>
        )}
        {showLogin && (
          <div id="login" style={{ opacity: loginOpacity1 && 1 }}>
            <h2>Login</h2>
            <form action="">
              <div id="inputs">
                <div id="user">
                  <h3>Usuário</h3>
                  <input type="text" />
                </div>
                <div id="password">
                  <h3>Senha</h3>
                  <input type="password" />
                </div>
              </div>
            </form>
            <Link to="/home">
              <button>Entrar</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
