import React, { useEffect, useState } from "react";
import imgUser from "../imgs/usuario.jpg";
import logoVerdeSemTagline from "../imgs/Fonte-Sul-verde-positivo-sem-tagline-removebg.png";

function Home() {
  const [data, setData] = useState({});

  useEffect(() => {
    fetch("/api")
      .then((response) => {
        return response.json(); // Return parsed JSON data
      })
      .then((data) => {
        setData(data); // Set state with parsed JSON data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <header id="homeHeader">
        <img
          className="logoHome"
          src={logoVerdeSemTagline}
          alt="logo FonteSul"
        />
        <div id="nav-bar">
          <a className="nav-item active" href="/home.html">
            Home
          </a>
          <a className="nav-item" href="/resumo.html">
            Resumo
          </a>
          <img
            className="nav-item profile-picture"
            src={imgUser}
            alt="profile"
          />
        </div>
      </header>
      <div id="mainHome">
        <div>
          <h1>Olá, Cliente!</h1>
          <h2>Aqui estão os seus sistemas conectados:</h2>
          {typeof data.users === "undefined" ? (
            <p>Loading...</p>
          ) : (
            data.users.map((user, i) => <p key={i}>{user}</p>)
          )}
        </div>
        <div className="card">
          <div className="card-title">
            <h3>Iluminação</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
